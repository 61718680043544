"use strict";

var isMobileVersion = true;
$(document).ready(function () {
  //переключатели меню
  $('.menu-switch').click(function () {
    if ($(this).hasClass('active')) {
      if ($('.menu-category').hasClass('active') || $('.menu-page').hasClass('active')) {
        if ($('.menu-category').hasClass('active')) {
          $('.menu-category').toggleClass('active');
        }

        if ($('.menu-page').hasClass('active')) {
          $('.menu-page').toggleClass('active');
        }
      } else {
        $('.menu-main').toggleClass('active');
      }
    } else {
      $('.menu-main').toggleClass('active');
    }

    $(this).toggleClass('active');
    $('body').toggleClass('not-scrolling');
  });
  $('.menu-catalog-toggle').click(function () {
    $('.menu-main').toggleClass('active');
    $('.menu-category').toggleClass('active');
  });
  $('.menu-info-toggle').click(function () {
    $('.menu-page').toggleClass('active');
    $('.menu-main').toggleClass('active');
  });
  $('.sub-category-button').click(function () {
    $('.menu-switch').toggleClass('active');
    $('.menu-category').toggleClass('active');
    var urlThisCategory = '/shop/categories/' + $(this).data('url');
    $('.menu-category a[href^="' + urlThisCategory + '"]').each(function () {
      var parentCategoryLink = $('.menu-category a[href^="' + urlThisCategory + '"]').parent().parent().parent().find('a.toggle-sub-menu');
      var subMenu = $('.menu-category a[href^="' + urlThisCategory + '"]').parents('.sub-menu');

      if (parentCategoryLink.length) {
        var iconToggle = parentCategoryLink.find('.icon-after-title i');
        iconToggle.css({
          'transform': 'rotate(90deg)'
        });
        subMenu.slideDown();
      }
    });
  });
  $('.toggle-sub-menu .icon-after-title').click(function (event) {
    event.preventDefault();
    var menuClick = $(this);
    var icon = $(this).find('i');
    $(this).toggleClass('active');

    if ($(this).hasClass('active')) {
      icon.css({
        'transform': 'rotate(90deg)'
      });
      $(this).parent().parent().find('.sub-menu').slideDown();
    } else {
      icon.css({
        'transform': 'rotate(0)'
      });
      $(this).parent().parent().find('.sub-menu').slideUp();
    }
  });
  $('.toggle-menu-index').click(function () {
    if ($(this).hasClass('active')) {
      $(this).find('.title-menu-index').text('открыть каталог');
      $(this).find('.toggle-menu-icon i').removeClass('fas fa-caret-up');
      $(this).find('.toggle-menu-icon i').addClass('far fa-bars');
      $('.text-logo-secondary').slideDown();
      $(this).parent().find('ul').slideUp();
    } else {
      $(this).find('.title-menu-index').text('свернуть каталог');
      $(this).find('.toggle-menu-icon i').removeClass('far fa-bars');
      $(this).find('.toggle-menu-icon i').addClass('fas fa-caret-up');
      $('.text-logo-secondary').slideUp();
      $(this).parent().find('ul').slideDown();
    }

    $(this).toggleClass('active');
  }); //конец переключатели меню

  getWidgetCartData();
  getWidgetWishlistData();
  $('span.price-to-locale').each(function () {
    $(this).text(parseInt($(this).text()).toLocaleString('ru'));
  });
}); //подгрузка данных для виджета корзины

function getWidgetCartData() {
  var widgetCart = {
    count: 0,
    sum: 0
  };
  axios.get('/ajax/json/cart-widget').then(function (response) {
    if (response.data) {
      widgetCart = response.data;
    }

    $('.header-icon-link .count-cart').text(widgetCart.count);

    if (widgetCart.sum) {
      $('.cart-summary-menu .cart-sum').text(widgetCart.sum.toLocaleString('ru') + ' ₽');
      $('.cart-summary-menu .cart-count').text(widgetCart.count);
    } else {
      $('.cart-summary-menu .cart-sum').text('корзина пуста');
      $('.cart-summary-menu .cart-count').text(widgetCart.count);
    }
  }).catch(function (error) {//console.log(error);
  });
} //подгрузка данных для виджета избранного


function getWidgetWishlistData() {
  var wishlistWidget = {
    count: 0
  };
  axios.get('/ajax/json/wishlist-widget').then(function (response) {
    if (response.data) {
      wishlistWidget = response.data;
    }

    $('.header-icon-link .count-wishlist').text(wishlistWidget.count);
  }).catch(function (error) {//console.log(error);
  });
}
